<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="mainbox">
            <div class="err">5</div>
            <div class="err1">0</div>
            <div class="err2">0</div>
            <div class="msg">Internal error occurred. 
              <br>Contact your system administrator for assistance.
              <br><p>Back to <a href="http://localhost:8080/">Dashboard</a> for now.</p></div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page500'
}
</script>
<style scoped>
  
body {
  background-color: #2399e2;
}

.mainbox {
  background-color: #1355e4;
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
}

  .err {
    color: #f06f06;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 20%;
    top: 8%;
  }

.err1 {
    color: #f06f06;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 42%;
    top: 8%;
  }

 .err2 {
    color: #f06f06;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 65%;
    top: 8%;
  }

.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
  }

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
}
</style>